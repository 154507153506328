"use strict"

export type ContentRange = [number, number]
export type ContainerRange = [number, number]
export type Unit = `px` | `vw` | `vh` | `%` | `rem` | `em`

/**
 * Clamp a dimension of a node within a given range while still responding to viewport width.
 * (This is loosely based on WebGL's clamp function.)
 * @param {ContentRange} content - A tuple of the minimum and maximum allowable width of the clamped content.
 * @param {ContainerRange} container - A tuple of the minimum and maximum allowable width of the content's container.
 * @param {Unit} unit - The CSS unit used to size the content — fixed units, like pixels, are ideal.
 * @returns {string} - A string containing a CSS calc() function
 * @example <h1 style={{ fontSize: clamp.between([24, 96], [325, 1600], `px`) }}>Clamped Heading</h1>
 */

function between(
  content: ContentRange,
  container: ContainerRange,
  unit: Unit
): string {
  const [minSize, maxSize] = content
  const [minWidth, maxWidth] = container

  return `calc(\
    ${minSize}${unit} + (${maxSize} - ${minSize}) \
    * ((100vw - ${minWidth}${unit}) \
    / (${maxWidth} - ${minWidth}))\
  )`
}

/**
 * Shortcut for using clamp.between() with ems.
 * @param {ContentRange} content - A tuple of the minimum and maximum allowable width (in em) of the clamped content.
 * @param {ContainerRange} container - A tuple of the minimum and maximum allowable width (in em) of the content's container.
 */
function em(content: ContentRange, container: ContainerRange): string {
  return between(content, container, `em`)
}

/**
 * Shortcut for using clamp.between() with percentages.
 * @param {ContentRange} content - A tuple of the minimum and maximum allowable width (in %) of the clamped content.
 * @param {ContainerRange} container - A tuple of the minimum and maximum allowable width (in %) of the content's container.
 */
function percent(content: ContentRange, container: ContainerRange): string {
  return between(content, container, `%`)
}

/**
 * Shortcut for using clamp.between() with pixels.
 * @param {ContentRange} content - A tuple of the minimum and maximum allowable width (in px) of the clamped content.
 * @param {ContainerRange} container - A tuple of the minimum and maximum allowable width (in px) of the content's container.
 */
function pixels(content: ContentRange, container: ContainerRange): string {
  return between(content, container, `px`)
}

/**
 * Shortcut for using clamp.between() with rems.
 * @param {ContentRange} content - A tuple of the minimum and maximum allowable width (in rem) of the clamped content.
 * @param {ContainerRange} container - A tuple of the minimum and maximum allowable width (in rem) of the content's container.
 */
function rem(content: ContentRange, container: ContainerRange): string {
  return between(content, container, `rem`)
}

export { between, em, percent, pixels, rem }
