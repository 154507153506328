import { IconType } from "react-icons"
import {
  FcCollaboration,
  FcFinePrint,
  FcIdea,
  FcSportsMode,
  FcWorkflow,
} from "react-icons/fc"

type ICoreValue = {
  description: string
  icon: IconType
  title: string
}

type ICoreValues = ICoreValue[]

const CoreValues: ICoreValues = [
  {
    title: `A little bit of give-a-damn goes a long way.`,
    icon: FcCollaboration,
    description: `Empathy is fundamental to everything we do. We listen, ask questions, and we educate, and we never make assumptions about our clients' preferences. We're not homebuilders until the whistle blows at 5pm — we're homebuilders 24 hours a day, 7 days a week, 365 days a year.`,
  },
  {
    title: `If we haven't gone the extra mile, we keep going.`,
    icon: FcSportsMode,
    description: `"Just enough" isn't good enough. We refuse to compromise on our clients' peace of mind and our standards of quality. It's not always the most profitable way to run a business, but it is always the right way.`,
  },
  {
    title: `Homebuilding is laden with details. All of them matter.`,
    icon: FcFinePrint,
    description: `The importance of our work to the lives of our clients factors into every decision we make. We don't skip steps, cut corners, or take shortcuts. We'd rather finish a well-built home late than a sub-standard home on time.`,
  },
  {
    title: `A clean, organized jobsite is the foundation of a clean, organized build.`,
    icon: FcWorkflow,
    description: `We believe our jobsites are reflections of ourselves, our contractors, our suppliers, and most importantly, our clients. We work hard to ensure that they remain tidy, organized, and litter-free throughout the build process.`,
  },
  {
    title: `Don't be afraid to change, but don't be afraid to stay the same.`,
    icon: FcIdea,
    description: `Change is only as good as the reason for changing, and for us, that reason is the client experience. While we're always in search of ways to improve — and quick to adapt when we find one — we place little stock in staying on-trend.`,
  },
]

export { CoreValues, ICoreValue, ICoreValues }
