import * as React from "react"
import { BoxProps, chakra } from "@chakra-ui/react"
import { useEmbedly } from "../utils"

// reference: https://docs.embed.ly/docs/cards#customize
interface oEmbedVideoProps extends BoxProps {
  dataCardAlign?: `left` | `center` | `right`
  dataCardChrome?: 0 | 1
  dataCardControls?: 0 | 1
  dataCardTheme?: `light` | `dark`
  dataCardWidth?: string
  oEmbedUrl: string
  videoDescription?: string
}

function EmbededVideo({
  dataCardAlign = `center`,
  dataCardChrome = 0,
  dataCardControls = 0,
  dataCardTheme = `light`,
  dataCardWidth = `100%`,
  oEmbedUrl,
  videoDescription = `Embedded Video`,
  ...rest
}: oEmbedVideoProps): JSX.Element {
  useEmbedly()

  return (
    <chakra.div
      dangerouslySetInnerHTML={{
        __html: `
        <a
          class="embedly-card"
          data-card-align="${dataCardAlign}"
          data-card-chrome="${dataCardChrome}"
          data-card-controls="${dataCardControls}"
          data-card-theme="${dataCardTheme}"
          data-card-width="${dataCardWidth}"
          href="${oEmbedUrl}"
        >
          ${videoDescription}
        </a>
      `,
      }}
      style={{
        marginBottom: -7,
        marginLeft: -2,
        marginRight: -2,
        marginTop: -7,
      }}
      {...rest}
    />
  )
}

export default EmbededVideo
