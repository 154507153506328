import { Stack, StackProps, Text } from "@chakra-ui/react"
import * as React from "react"

import EmbededVideo from "./oEmbedVideo"

export interface FAQVideoProps extends StackProps {
  question: string
  questionTitle?: string
  oEmbedUrl: string
  videoDescription: string
}

function FAQVideo({
  question,
  questionTitle = `FAQ`,
  oEmbedUrl,
  videoDescription,
  ...rest
}) {
  const formattedQuestionTitle =
    questionTitle[questionTitle.length - 1] === `:`
      ? questionTitle
      : questionTitle + `:`

  const formattedQuestion = question.replace(/"/g, ``)

  return (
    <Stack
      mt={{ base: 6, lg: 0 }}
      px={2}
      py={4}
      shadow="lg"
      spacing="12px"
      rounded="lg"
      w="100%"
      {...rest}
    >
      <EmbededVideo oEmbedUrl={oEmbedUrl} videoDescription={videoDescription} />
      <Text px={2}>
        <strong>{formattedQuestionTitle}</strong> "{formattedQuestion}"
      </Text>
    </Stack>
  )
}

export default FAQVideo
