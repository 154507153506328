import { useEffect } from "react"

interface UseEmbedlyProps {
  src?: string
  script?: string
}

function useEmbedly(props?: UseEmbedlyProps) {
  const { src, script } = Object.assign(
    {
      src: `/embedly.js`,
      script: null,
    },
    props
  )

  return useEffect(() => {
    const embedlyJS = document.createElement(`script`)

    if (!script) {
      embedlyJS.src = src
    } else {
      embedlyJS.textContent = script
    }

    document.body.appendChild(embedlyJS)

    const embedlyCSS = document.createElement(`link`)
    embedlyCSS.rel = `stylesheet`
    embedlyCSS.className = `embedly-css`
    embedlyCSS.href = `/embedly.css`
    document.head.appendChild(embedlyCSS)

    return () => {
      document.body.removeChild(embedlyJS)
      document.head.removeChild(embedlyCSS)
    }
  }, [src, script])
}

export default useEmbedly
